@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
ul {
  padding: 0;
}

.first-ynet-course {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "NarkisBlockCondensed-Thin", sans-serif;
}
.first-ynet-course .title {
  color: #143f90;
  font-size: 2.34375vw;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
}
@media only screen and (max-width: 767px) {
  .first-ynet-course .title {
    text-align: center;
    font-size: 7.4666666667vw;
    line-height: 7.4666666667vw;
  }
}
.first-ynet-course .logo {
  height: 1.9270833333vw;
}
@media only screen and (max-width: 767px) {
  .first-ynet-course .logo {
    margin-top: 1.3333333333vw;
    height: 6.4vw;
    margin-bottom: 7.4666666667vw;
  }
}
.first-ynet-course .logo img {
  height: 100%;
}
.first-ynet-course .options-block {
  margin-top: 0.9375vw;
  width: 43.59375vw;
}
@media only screen and (max-width: 767px) {
  .first-ynet-course .options-block {
    margin-top: 4vw;
    width: 75.4666666667vw;
  }
}
.first-ynet-course .options-block .title > div {
  margin-bottom: 0.78125vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(198, 156, 108, 0.6666666667);
  padding: 0 0.3645833333vw;
  width: fit-content;
  height: 1.6666666667vw;
  font-size: 1.5104166667vw;
  color: #000;
}
@media only screen and (max-width: 767px) {
  .first-ynet-course .options-block .title > div {
    height: 8.5333333333vw;
    font-size: 7.7333333333vw;
    padding: 1.3333333333vw;
  }
}
.first-ynet-course .options-block .options {
  font-size: 1.1979166667vw;
  padding-right: 1.3020833333vw;
  line-height: 113%;
}
@media only screen and (max-width: 767px) {
  .first-ynet-course .options-block .options {
    font-size: 6.1333333333vw;
    line-height: 6.9333333333vw;
    padding-right: 7.2vw;
  }
}
.first-ynet-course .options-block .options li {
  list-style-type: none;
  position: relative;
}
.first-ynet-course .options-block .options li::before {
  content: "•";
  font-family: FontAwesome;
  position: absolute;
  right: -1.25vw;
  top: 0.15625vw;
  font-size: 2.03125vw;
}
@media only screen and (max-width: 767px) {
  .first-ynet-course .options-block .options li::before {
    font-size: 10.6666666667vw;
    right: -5.8666666667vw;
  }
}
.first-ynet-course .options-block .options li.bold {
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
}
.first-ynet-course .options-block .options li.bottom-gap {
  margin-bottom: 1.5625vw;
}
@media only screen and (max-width: 767px) {
  .first-ynet-course .options-block .options li.bottom-gap {
    margin-bottom: 6.6666666667vw;
  }
}
.first-ynet-course .bottom-text {
  margin-top: -1.0416666667vw;
  margin-right: 0.5208333333vw;
  width: 43.59375vw;
  color: #c69c6c;
  font-size: 1.8229166667vw;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
}
@media only screen and (max-width: 767px) {
  .first-ynet-course .bottom-text {
    font-size: 9.3333333333vw;
    width: 65.3333333333vw;
  }
}
.first-ynet-course .open-form-btn {
  margin-top: 2.7083333333vw;
  margin-bottom: 3.5416666667vw;
  width: 15.9375vw;
  height: 2.8125vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.5625vw;
  background-color: #143f90;
  border-radius: 3.28125vw;
}
@media only screen and (max-width: 767px) {
  .first-ynet-course .open-form-btn {
    margin-top: 12vw;
    margin-bottom: 13.3333333333vw;
    font-size: 8vw;
    width: 81.3333333333vw;
    height: 14.4vw;
    border-radius: 16.8vw;
  }
}