@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
@value transitionIn: 1000ms;
@value transitionOut: 1000ms;
.intro {
  width: 100%;
  padding-top: 4.7916666667vw;
  overflow-x: hidden;
}
@media only screen and (max-width: 767px) {
  .intro {
    padding-top: 12.5333333333vw;
  }
}
.intro.appear, .intro.enter {
  opacity: 0;
}
.intro.appearing, .intro.entering {
  opacity: 1;
  transition: opacity;
  transition-duration: transitionIn;
}
.intro.exit {
  opacity: 1;
}
.intro.exiting {
  opacity: 0;
  transition: opacity;
  transition-duration: transitionOut;
}
.intro a {
  text-decoration: none;
  color: #31302a;
}
.intro .content-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 65.8333333333vw;
  background-size: cover;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 767px) {
  .intro .content-bg {
    height: 65.625vw;
  }
}
.intro .content-bg-mob {
  display: none;
}
@media only screen and (max-width: 767px) {
  .intro .content-bg-mob {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 37.8125vw;
    background-size: contain;
    background-position: 0% 0%;
    background-repeat: no-repeat;
  }
}
@media only screen and (max-width: 767px) {
  .intro .content-bg-mob::after {
    content: "";
    position: absolute;
    top: 10.3125vw;
    left: 0;
    width: 100%;
    height: 108.90625vw;
    background: -moz-linear-gradient(top, rgba(30, 87, 153, 0) 0%, rgb(233, 242, 165) 25%, rgb(255, 255, 255) 80%);
    background: -webkit-linear-gradient(top, rgba(30, 87, 153, 0) 0%, rgb(233, 242, 165) 25%, rgb(255, 255, 255) 80%);
    background: linear-gradient(to bottom, rgba(30, 87, 153, 0) 0%, rgb(233, 242, 165) 25%, rgb(255, 255, 255) 80%);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr="#001e5799", endColorstr="#ffffff", GradientType=0);
  }
}
.intro .content {
  position: relative;
  z-index: 6;
  padding-top: 3.75vw;
}
@media only screen and (max-width: 767px) {
  .intro .content {
    padding-top: 6.5625vw;
  }
}
.intro .content .logo-container {
  position: relative;
  top: 0;
  z-index: 2;
  width: 100%;
  padding-bottom: 2.5520833333vw;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .intro .content .logo-container {
    margin-bottom: 3.125vw;
  }
}
.intro .content .logo-container .mainLogo {
  width: 18.90625vw;
}
@media only screen and (max-width: 767px) {
  .intro .content .logo-container .mainLogo {
    width: 22.1875vw;
  }
}
.intro .content .logo-container .mainLogo img {
  width: 100%;
}
.intro .content .logo-container .tagline {
  margin-top: 0.2604166667vw;
  font-size: 2.2916666667vw;
  font-family: "NarkisBlock-Regular", sans-serif;
}
.intro .content .content-wrapper {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 50.3645833333vw;
  margin: 0 auto;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .intro .content .content-wrapper {
    max-width: inherit;
    padding: 0 4.375vw;
  }
}
@media only screen and (max-width: 767px) {
  .intro .content .content-wrapper {
    max-width: 100%;
    padding: 0 4.8vw;
    margin: 25.8666666667vw auto 0;
  }
}
.intro .content .main-content {
  padding: 0.46875vw 1.40625vw 0 1.40625vw;
  background-color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .intro .content .main-content {
    padding: 19.7px 2.34375vw 19.7px 2.34375vw;
  }
}
@media only screen and (max-width: 767px) {
  .intro .content .main-content {
    padding: 4.2666666667vw 1.09375vw 3.234375vw 1.09375vw;
  }
}
.intro .content .main-content .texts .section-title p {
  padding-bottom: 0.625vw;
  font-size: 1.40625vw;
  line-height: 1.4583333333vw;
  color: #000000;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .intro .content .main-content .texts .section-title p {
    padding-bottom: 1.875vw;
    font-size: 4.21875vw;
    line-height: 4.375vw;
  }
}
.intro .content .main-content .texts .section-title p:first-child {
  font-size: 1.8229166667vw;
  line-height: 2.1354166667vw;
  letter-spacing: 0.81px;
  color: #143f90;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .intro .content .main-content .texts .section-title p:first-child {
    padding-bottom: 1.09375vw;
    font-size: 5.46875vw;
    line-height: 6.40625vw;
    letter-spacing: 0.21px;
  }
}
.intro .content .main-content .texts .section-title p span {
  font-size: 1.1458333333vw;
  line-height: 1.25vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .intro .content .main-content .texts .section-title p span {
    font-size: 3.4375vw;
    line-height: 3.75vw;
  }
}
@media only screen and (max-width: 767px) {
  .intro .content .main-content .texts .section-title p:last-child {
    white-space: nowrap;
    letter-spacing: -0.078125vw;
  }
}

.job-description1 {
  font-size: 1.1979166667vw;
  line-height: 1.40625vw;
  font-family: "NarkisBlockCondensed-Thin", sans-serif;
  margin-bottom: 0.4166666667vw;
}
@media only screen and (max-width: 767px) {
  .job-description1 {
    font-size: 4.21875vw;
    line-height: 4.21875vw;
    max-width: 87.03125vw;
    margin-bottom: 2.34375vw;
  }
}
.job-description1 .title {
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
}
.job-description1 .subtitle {
  margin-top: 1.5625vw;
}
@media only screen and (max-width: 767px) {
  .job-description1 .subtitle {
    margin-top: 3.125vw;
  }
}
.job-description1 .subtitle span {
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
}

.job-description2 {
  font-size: 1.1979166667vw;
  line-height: 1.40625vw;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  font-weight: 100;
  margin-bottom: 0.4166666667vw;
}
@media only screen and (max-width: 767px) {
  .job-description2 {
    max-width: 87.03125vw;
    font-size: 4.21875vw;
    line-height: 4.21875vw;
    margin-bottom: 2.34375vw;
  }
}

.job-description3 {
  font-size: 1.1979166667vw;
  line-height: 1.40625vw;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  font-weight: 100;
  margin-bottom: 0.4166666667vw;
}
@media only screen and (max-width: 767px) {
  .job-description3 {
    max-width: 87.03125vw;
    font-size: 4.21875vw;
    line-height: 4.21875vw;
    margin-bottom: 2.34375vw;
  }
}

.jobDescription4 {
  font-size: 1.1979166667vw;
  line-height: 1.40625vw;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  font-weight: 100;
  margin-bottom: 0.4166666667vw;
}
@media only screen and (max-width: 767px) {
  .jobDescription4 {
    max-width: 87.03125vw;
    font-size: 4.21875vw;
    line-height: 4.21875vw;
    margin-bottom: 2.34375vw;
  }
}

.section-one {
  font-family: "NarkisBlockCondensed-Thin", sans-serif;
  font-size: 1.1979166667vw;
  line-height: 1.40625vw;
  margin-top: 1.1458333333vw;
}
.section-one .colored.opened span .arrow {
  transform: translate(0%, -45%) rotate(180deg);
}
.section-one .colored span {
  width: 17.7083333333vw;
  position: relative;
  padding: 0 0.46875vw 0 1.3020833333vw;
  display: inline-block;
  background-color: rgba(245, 130, 32, 0.61);
  color: #000000;
  font-size: 1.5104166667vw;
  line-height: 1.7708333333vw;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  white-space: nowrap;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .section-one .colored span {
    padding: 0 1.40625vw 0 3.90625vw;
    font-size: 4.53125vw;
    line-height: 5.3125vw;
    width: 54.6875vw;
  }
}
.section-one .colored span b {
  font-size: 1.09375vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.section-one .colored span .arrow {
  display: block;
  width: 0.9895833333vw;
  position: absolute;
  top: 50%;
  margin-left: 0.5208333333vw;
  left: 0;
  transform: translate(0%, -45%) rotate(0);
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .section-one .colored span .arrow {
    width: 3.125vw;
    margin-left: 1.5625vw;
  }
}
.section-one .colored.small-bottom-padding {
  padding-bottom: 0.2083333333vw;
}
.section-onecontent {
  margin-top: 0.2604166667vw;
}
@media only screen and (max-width: 767px) {
  .section-onecontent {
    margin-top: 2.34375vw;
  }
}
.section-onecontent span {
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
}
.section-onecontent .second-part {
  margin-top: 1.09375vw;
}
.section-onecontent .second-part a {
  color: #ee5512;
  text-decoration: underline;
}
.section-onecontent .second-part span {
  font-size: 1.1979166667vw;
  color: #ee5512;
  font-family: "Poalim-Regular", sans-serif;
}
@media only screen and (max-width: 767px) {
  .section-onecontent .second-part span {
    font-size: 4.21875vw;
    line-height: 4.21875vw;
  }
}
.section-onecontent .third-part {
  margin-top: 1.09375vw;
}

.section-two {
  font-family: "NarkisBlockCondensed-Thin", sans-serif;
  font-size: 1.1458333333vw;
  line-height: 1.40625vw;
}
.section-two .colored {
  padding-bottom: 1.0416666667vw;
}
@media only screen and (max-width: 767px) {
  .section-two .colored {
    padding-bottom: 3.125vw;
  }
}
.section-two .colored.opened span .arrow {
  transform: translate(0%, -45%) rotate(180deg);
}
.section-two .colored span {
  position: relative;
  width: 12.2395833333vw;
  padding: 0 0.2604166667vw 0 1.3020833333vw;
  display: inline-block;
  background-color: rgba(245, 130, 32, 0.61);
  color: #000000;
  font-size: 1.5104166667vw;
  line-height: 1.7708333333vw;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .section-two .colored span {
    padding: 0 0.78125vw 0 3.90625vw;
    font-size: 4.53125vw;
    line-height: 5.3125vw;
    width: 37.5vw;
  }
}
.section-two .colored span b {
  font-size: 1.3020833333vw;
  line-height: 1.7708333333vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .section-two .colored span b {
    font-size: 3.90625vw;
    line-height: 5.3125vw;
  }
}
.section-two .colored span .arrow {
  display: block;
  width: 0.9895833333vw;
  position: absolute;
  top: 50%;
  margin-left: 0.5208333333vw;
  left: 0;
  transform: translate(0%, -45%) rotate(0);
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .section-two .colored span .arrow {
    width: 3.125vw;
    margin-left: 1.5625vw;
  }
}
.section-two .colored.small-bottom-padding {
  padding-bottom: 0.2083333333vw;
}
.section-two-content {
  margin-top: 0.2604166667vw;
}
@media only screen and (max-width: 767px) {
  .section-two-content {
    margin-top: 2.34375vw;
  }
}
.section-two-content span {
  color: #ee5512;
  font-size: 1.1614583333vw;
  font-family: "Poalim-Regular", sans-serif;
}
@media only screen and (max-width: 767px) {
  .section-two-content span {
    font-size: 4.21875vw;
    line-height: 4.21875vw;
  }
}

.section-three {
  font-family: "NarkisBlockCondensed-Thin", sans-serif;
  font-size: 1.1979166667vw;
  line-height: 1.40625vw;
}
.section-three .colored {
  padding-bottom: 0.5208333333vw;
}
@media only screen and (max-width: 767px) {
  .section-three .colored {
    padding-bottom: 1.5625vw;
    line-height: 1;
  }
}
.section-three .colored span {
  width: 12.2395833333vw;
  position: relative;
  padding: 0 0.2604166667vw;
  display: inline-block;
  background-color: rgba(245, 130, 32, 0.61);
  color: #000000;
  font-size: 1.5104166667vw;
  line-height: 1.7708333333vw;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  white-space: nowrap;
  letter-spacing: 0.4px;
}
@media only screen and (max-width: 767px) {
  .section-three .colored span {
    padding: 0 0.78125vw;
    font-size: 4.53125vw;
    line-height: 5.3125vw;
    letter-spacing: 0.76px;
    width: 37.5vw;
  }
}
.section-three .colored span b {
  font-size: 1.09375vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .section-three .colored span b {
    font-size: 3.28125vw;
  }
}
.section-three .colored span .arrow {
  display: block;
  width: 0.9895833333vw;
  margin-left: 0.5208333333vw;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -45%) rotate(0);
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .section-three .colored span .arrow {
    left: -0.9375vw;
    width: 3.125vw;
    margin-left: 1.5625vw;
  }
}
.section-three .colored.opened span .arrow {
  transform: translate(0%, -45%) rotate(180deg);
}
.section-three .colored.small-bottom-padding {
  padding-bottom: 0.2083333333vw;
  cursor: pointer;
}
.section-three .colored.small-bottom-padding span {
  padding: 0 0.2604166667vw 0 1.3020833333vw;
}
@media only screen and (max-width: 767px) {
  .section-three .colored.small-bottom-padding span {
    padding: 0 0.78125vw 0 3.90625vw;
  }
}
@media only screen and (max-width: 767px) {
  .section-three .colored.small-bottom-padding {
    padding-bottom: 0.78125vw;
  }
}
@media only screen and (max-width: 767px) {
  .section-three .colored.show-on-desktop {
    display: none;
  }
}
.section-three .colored.show-on-mobile {
  display: none;
}
@media only screen and (max-width: 767px) {
  .section-three .colored.show-on-mobile {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .section-three .colored.with-big-padding span {
    padding: 0 0.78125vw 0 2.34375vw;
  }
}
.section-three-content {
  margin-top: 0.2604166667vw;
}
@media only screen and (max-width: 767px) {
  .section-three-content {
    margin-top: 2.34375vw;
  }
}
.section-three-content span {
  color: #ee5512;
  font-size: 1.1979166667vw;
  font-family: "Poalim-Regular", sans-serif;
}
@media only screen and (max-width: 767px) {
  .section-three-content span {
    font-size: 4.21875vw;
    line-height: 4.21875vw;
  }
}

.section-four {
  font-family: "NarkisBlockCondensed-Thin", sans-serif;
  font-size: 1.1197916667vw;
  line-height: 1.40625vw;
}
.section-four .colored {
  padding-bottom: 0.78125vw;
}
@media only screen and (max-width: 767px) {
  .section-four .colored {
    padding-bottom: 2.34375vw;
  }
}
.section-four .colored.opened span .arrow {
  transform: translate(0%, -45%) rotate(180deg);
}
.section-four .colored span {
  width: 12.2395833333vw;
  position: relative;
  padding: 0 0.46875vw 0 1.3020833333vw;
  display: inline-block;
  background-color: rgba(245, 130, 32, 0.61);
  color: #000000;
  font-size: 1.5104166667vw;
  line-height: 1.7708333333vw;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  white-space: nowrap;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .section-four .colored span {
    padding: 0 1.40625vw 0 3.90625vw;
    font-size: 4.53125vw;
    line-height: 5.3125vw;
    width: 37.5vw;
  }
}
.section-four .colored span b {
  font-size: 1.09375vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.section-four .colored span .arrow {
  display: block;
  width: 0.9895833333vw;
  margin-left: 0.5208333333vw;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -45%) rotate(0);
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .section-four .colored span .arrow {
    width: 3.125vw;
    margin-left: 1.5625vw;
  }
}
.section-four .colored.small-bottom-padding {
  padding-bottom: 0.2083333333vw;
}
.section-four-content {
  margin-top: 0.2604166667vw;
}
@media only screen and (max-width: 767px) {
  .section-four-content {
    margin-top: 2.34375vw;
  }
}
.section-four-content span {
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
}
.section-four-content .office {
  font-family: "Poalim-Light", sans-serif;
}

.section-two1 {
  margin-top: 0.9895833333vw;
}

.section-five {
  margin-top: 0.9895833333vw;
  font-family: "NarkisBlockCondensed-Thin", sans-serif;
  font-size: 1.1979166667vw;
  line-height: 1.40625vw;
}
.section-five .colored {
  padding-bottom: 0.78125vw;
}
@media only screen and (max-width: 767px) {
  .section-five .colored {
    padding-bottom: 2.34375vw;
  }
}
.section-five .colored.opened span .arrow {
  transform: translate(0%, -45%) rotate(180deg);
}
.section-five .colored span {
  width: 12.2395833333vw;
  position: relative;
  padding: 0 0.46875vw 0 1.3020833333vw;
  display: inline-block;
  background-color: rgba(245, 130, 32, 0.61);
  color: #000000;
  font-size: 1.5104166667vw;
  line-height: 1.7708333333vw;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  white-space: nowrap;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .section-five .colored span {
    padding: 0 1.40625vw 0 3.90625vw;
    font-size: 4.53125vw;
    line-height: 5.3125vw;
    width: 37.5vw;
  }
}
.section-five .colored span b {
  font-size: 1.09375vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.section-five .colored span .arrow {
  display: block;
  margin-left: 0.5208333333vw;
  width: 0.9895833333vw;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -45%) rotate(0);
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .section-five .colored span .arrow {
    width: 3.125vw;
    margin-left: 1.5625vw;
  }
}
.section-five .colored.small-bottom-padding {
  padding-bottom: 0.2083333333vw;
}
.section-five-content {
  margin-top: 0.2604166667vw;
}
@media only screen and (max-width: 767px) {
  .section-five-content {
    margin-top: 2.34375vw;
  }
}
.section-five-content span {
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
}
.section-five-content .office {
  font-family: "Poalim-Regular", sans-serif;
}

.section-six {
  margin-top: 0.9895833333vw;
  font-family: "NarkisBlockCondensed-Thin", sans-serif;
  font-size: 1.1979166667vw;
  line-height: 1.40625vw;
}
.section-six .colored {
  padding-bottom: 0.78125vw;
}
@media only screen and (max-width: 767px) {
  .section-six .colored {
    padding-bottom: 2.34375vw;
  }
}
.section-six .colored.opened span .arrow {
  transform: translate(0%, -45%) rotate(180deg);
}
.section-six .colored span {
  width: 12.2395833333vw;
  position: relative;
  padding: 0 0.46875vw 0 1.3020833333vw;
  display: inline-block;
  background-color: rgba(245, 130, 32, 0.61);
  color: #000000;
  font-size: 1.5104166667vw;
  line-height: 1.7708333333vw;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  white-space: nowrap;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .section-six .colored span {
    padding: 0 1.40625vw 0 3.90625vw;
    font-size: 4.53125vw;
    line-height: 5.3125vw;
    width: 37.5vw;
  }
}
.section-six .colored span b {
  font-size: 1.09375vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.section-six .colored span .arrow {
  margin-left: 0.5208333333vw;
  display: block;
  width: 0.9895833333vw;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -45%) rotate(0);
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .section-six .colored span .arrow {
    width: 3.125vw;
    margin-left: 1.5625vw;
  }
}
.section-six .colored.small-bottom-padding {
  padding-bottom: 0.2083333333vw;
}
.section-six-content {
  margin-top: 0.2604166667vw;
}
@media only screen and (max-width: 767px) {
  .section-six-content {
    margin-top: 2.34375vw;
  }
}
.section-six-content span {
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
}
.section-six-content .office {
  font-family: serif;
}

.section-seven {
  margin-top: 0.9895833333vw;
  font-family: "NarkisBlockCondensed-Thin", sans-serif;
  font-size: 1.1979166667vw;
  line-height: 1.40625vw;
}
.section-seven .colored {
  padding-left: 0.5208333333vw;
  padding-bottom: 0.78125vw;
  width: 11.3541666667vw;
}
@media only screen and (max-width: 767px) {
  .section-seven .colored {
    padding-bottom: 2.34375vw;
  }
}
.section-seven .colored.opened span .arrow {
  transform: translate(0%, -45%) rotate(180deg);
}
.section-seven .colored span {
  width: 12.2395833333vw;
  position: relative;
  padding: 0 0.46875vw 0 1.3020833333vw;
  display: inline-block;
  background-color: rgba(245, 130, 32, 0.61);
  color: #000000;
  font-size: 1.5104166667vw;
  line-height: 1.7708333333vw;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  white-space: nowrap;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .section-seven .colored span {
    width: 37.5vw;
    padding: 0 1.40625vw 0 3.90625vw;
    font-size: 4.53125vw;
    line-height: 5.3125vw;
  }
}
.section-seven .colored span b {
  font-size: 1.09375vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.section-seven .colored span .arrow {
  display: block;
  margin-left: 0.5208333333vw;
  width: 0.9895833333vw;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -45%) rotate(0);
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .section-seven .colored span .arrow {
    width: 3.125vw;
    margin-left: 1.5625vw;
  }
}
.section-seven .colored.small-bottom-padding {
  padding-bottom: 0.2083333333vw;
}
.section-seven-content {
  margin-top: 0.2604166667vw;
}
@media only screen and (max-width: 767px) {
  .section-seven-content {
    margin-top: 2.34375vw;
  }
}
.section-seven-content span {
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
}
.section-seven-content .office {
  font-family: serif;
}
.section-seven-content .list {
  margin-top: 0.5208333333vw;
}
.section-seven-content .list ul {
  padding-right: 1.3020833333vw;
}
.section-seven-content .bottom {
  margin-top: 0.5208333333vw;
}
.section-seven-content .bottom .email {
  color: #ee5512;
  font-size: 1.1979166667vw;
  font-family: "Poalim-Regular", sans-serif;
}
@media only screen and (max-width: 767px) {
  .section-seven-content .bottom .email {
    font-size: 4.21875vw;
    line-height: 4.21875vw;
  }
}

@media only screen and (max-width: 767px) {
  .section-one,
  .section-two,
  .section-three,
  .section-four,
  .section-five,
  .section-six,
  .section-seven {
    font-size: 4.21875vw;
    line-height: 4.21875vw;
  }
}