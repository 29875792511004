@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "NarkisBlockCondensed-Thin", sans-serif;
}
.main .texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.3020833333vw;
}
.main .texts .title {
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  font-size: 1.9791666667vw;
  color: #143f90;
}
@media only screen and (max-width: 767px) {
  .main .texts .title {
    width: 79.4666666667vw;
    font-size: 7.4666666667vw;
    line-height: 7.4666666667vw;
  }
}
.main .texts .first-subtitle {
  padding: 0 1.0416666667vw;
  margin-top: -0.2083333333vw;
  line-height: 1.3541666667vw;
}
@media only screen and (max-width: 767px) {
  .main .texts .first-subtitle {
    width: 86.6666666667vw;
    margin-top: 9.8666666667vw;
    margin-right: -2.4vw;
    font-size: 6.1333333333vw;
    line-height: 6.4vw;
  }
}
.main .texts .second-subtitle {
  margin-top: 1.3541666667vw;
  line-height: 1.3541666667vw;
}
@media only screen and (max-width: 767px) {
  .main .texts .second-subtitle {
    width: 84.8vw;
    margin-top: 6.4vw;
    margin-right: -2.4vw;
    font-size: 6.1333333333vw;
    line-height: 6.4vw;
  }
}
.main .texts .under-text {
  margin-top: 1.0416666667vw;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
}
@media only screen and (max-width: 767px) {
  .main .texts .under-text {
    width: 84.8vw;
    margin-top: 6.6666666667vw;
    margin-right: -2.4vw;
    font-size: 6.1333333333vw;
    line-height: 6.1333333333vw;
  }
}
.main .email-text {
  padding: 0.46875vw 1.3020833333vw;
  margin-top: 3.4375vw;
  background-color: #ffedd9;
  border-radius: 0.3645833333vw;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  font-size: 0.9375vw;
  white-space: nowrap;
}
@media only screen and (max-width: 767px) {
  .main .email-text {
    width: 88vw;
    padding: 2.4vw 1.0666666667vw 2.9333333333vw;
    margin-top: 5.8666666667vw;
    font-size: 5.6vw;
    line-height: 5.8666666667vw;
    border-radius: 1.6vw;
    white-space: unset;
    text-align: center;
  }
}
.main .email-text .email {
  color: #114090;
  font-family: "NarkisBlock-Light", sans-serif;
  text-decoration: underline;
}
.main .warning-wrap {
  display: flex;
  width: 38.0208333333vw;
  margin-top: 1.0416666667vw;
  margin-bottom: 3.2291666667vw;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .main .warning-wrap {
    width: 84.8vw;
    margin-top: 9.8666666667vw;
    margin-bottom: 8vw;
  }
}
.main .warning-wrap img {
  height: 4.21875vw;
}
@media only screen and (max-width: 767px) {
  .main .warning-wrap img {
    height: 17.8666666667vw;
  }
}
.main .warning-wrap .text {
  margin-top: 1.1458333333vw;
  color: #d9000d;
  font-size: 0.7291666667vw;
  line-height: 0.78125vw;
}
@media only screen and (max-width: 767px) {
  .main .warning-wrap .text {
    margin-top: 7.4666666667vw;
    font-size: 3.4666666667vw;
    line-height: 3.4666666667vw;
    text-align: center;
  }
}