@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.sended-form {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  direction: ltr;
  background-color: rgba(127, 139, 156, 0.71);
  opacity: 0;
  visibility: hidden;
  transition: all 1s ease;
}
.sended-form .title {
  color: #143f90;
  font-size: 2.7083333333vw;
  line-height: 2.0833333333vw;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sended-form .title {
    font-size: 8.125vw;
  }
}
.sended-form .text {
  font-size: 24px;
  line-height: 28px;
}
@media only screen and (max-width: 767px) {
  .sended-form .text {
    font-size: 3.75vw;
    line-height: 4.375vw;
  }
}
.sended-form .emailWrapper {
  font-size: 24px;
  line-height: 28px;
  color: #1f52a4;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
}
@media only screen and (max-width: 767px) {
  .sended-form .emailWrapper {
    font-size: 3.75vw;
    line-height: 4.375vw;
  }
}
.sended-form .emailWrapper .email {
  font-size: 23px;
  line-height: 28px;
  color: #04ab6c;
  text-decoration: underline;
  font-family: "Open Sans", sans-serif;
}
@media only screen and (max-width: 767px) {
  .sended-form .emailWrapper .email {
    font-size: 3.125vw;
    line-height: 4.375vw;
  }
}
.sended-form .footer-text {
  margin-top: 44px;
  font-size: 36px;
  line-height: 28px;
  font-family: "NarkisBlockCondensedMF-Bold", sans-serif;
}
@media only screen and (max-width: 767px) {
  .sended-form .footer-text {
    margin-top: 6.875vw;
    font-size: 5.625vw;
  }
}
.sended-form .sended-form-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  width: 670px;
  height: 262px;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  background-color: #fff;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  color: #143f90;
  font-size: 2.7083333333vw;
  line-height: 2.0833333333vw;
  letter-spacing: 0.88px;
}
@media only screen and (max-width: 767px) {
  .sended-form .sended-form-wrapper {
    height: 40.9375vw;
    max-width: 94.6875vw;
    line-height: 6.25vw;
    letter-spacing: 0.88px;
  }
}
.sended-form .sended-form-wrapper .close {
  width: 99%;
  display: flex;
  justify-content: flex-start;
  margin-top: 0.2604166667vw;
  margin-right: 0.2604166667vw;
}
@media only screen and (max-width: 767px) {
  .sended-form .sended-form-wrapper .close {
    margin-top: 0.78125vw;
    margin-right: 0.78125vw;
  }
}
.sended-form .sended-form-wrapper .close img {
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .sended-form .sended-form-wrapper .close img {
    width: 5.3125vw;
  }
}
.sended-form .sended-form-wrapper p {
  margin-top: 3.125vw;
  align-self: center;
  text-align: center;
}
.sended-form .sended-form-wrapper span {
  margin-top: 10%;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
}
@media only screen and (max-width: 767px) {
  .sended-form .sended-form-wrapper span {
    margin-top: 12.5vw;
  }
}
.sended-form[class*=show] {
  opacity: 1;
  visibility: visible;
}