@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.courses {
  display: grid;
  width: 38.0208333333vw;
  margin-top: 3.6458333333vw;
  grid-template-columns: repeat(2, 19.2708333333vw);
  grid-column-gap: 0;
  grid-row-gap: 1.25vw;
}
@media only screen and (max-width: 767px) {
  .courses {
    display: flex;
    width: 100%;
    margin: 3.4666666667vw 0 10.6666666667vw;
    flex-direction: column;
    flex-wrap: nowrap;
    row-gap: 12.8vw;
  }
}
.courses .course {
  position: relative;
  display: flex;
  min-height: 8.6979166667vw;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .courses .course {
    width: 100%;
    height: fit-content;
  }
}
.courses .course::before, .courses .course::after {
  content: "";
  position: absolute;
  background-color: rgba(20, 63, 144, 0.2);
}
.courses .course::after {
  right: 0;
  bottom: -0.625vw;
  width: 18.4895833333vw;
  height: 0.0520833333vw;
}
@media only screen and (max-width: 767px) {
  .courses .course::after {
    right: initial;
    bottom: -6.4vw;
    left: 50%;
    width: 90.1333333333vw;
    transform: translate(-50%);
  }
}
.courses .course::before {
  top: 0;
  left: 0;
  width: 0.0520833333vw;
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .courses .course::before {
    display: none;
  }
}
.courses .course:nth-of-type(odd) {
  padding-left: 10px;
}
@media only screen and (max-width: 767px) {
  .courses .course:nth-of-type(odd) {
    padding-left: 0;
  }
}
.courses .course:nth-of-type(even) {
  padding-right: 10px;
}
@media only screen and (max-width: 767px) {
  .courses .course:nth-of-type(even) {
    padding-right: 0;
  }
}
.courses .course:nth-of-type(even)::before {
  display: none;
}
.courses .course:nth-of-type(even)::after {
  right: initial;
  left: 0;
}
@media only screen and (max-width: 767px) {
  .courses .course:nth-of-type(even)::after {
    left: 50%;
    transform: translate(-50%);
  }
}
.courses .course:nth-of-type(even) .price-tag {
  right: 1.40625vw;
}
@media only screen and (max-width: 767px) {
  .courses .course:nth-of-type(even) .price-tag {
    right: 3.7333333333vw;
  }
}
.courses .course:nth-last-child(-n+1)::after {
  display: none;
}
.courses .course.disabled .logo {
  color: #7f7f7f;
}
.courses .course.disabled .logo img {
  opacity: 0.5;
}
.courses .course.disabled .title,
.courses .course.disabled .sub-title,
.courses .course.disabled .price-tag {
  opacity: 0.5;
}
.courses .course.disabled .btn {
  pointer-events: none;
  background-color: #ececec;
  color: #9d9d9d;
}
@media only screen and (max-width: 767px) {
  .courses .course.ynet .title {
    margin-top: 1.8666666667vw;
  }
}
.courses .course.ynet .sub-title {
  margin: 0.46875vw 0.78125vw 0.3125vw;
}
@media only screen and (max-width: 767px) {
  .courses .course.ynet .sub-title {
    margin: 3.7333333333vw 5.3333333333vw 2.1333333333vw;
    line-height: 1.1;
  }
}
.courses .course.ynet .sub-title i {
  display: inline-block;
  width: 0.4166666667vw;
  height: 0.4166666667vw;
  margin: 0 0.2604166667vw 0.0520833333vw;
  background-color: #143f90;
  border-radius: 50%;
}
@media only screen and (max-width: 767px) {
  .courses .course.ynet .sub-title i {
    width: 2.1333333333vw;
    height: 2.1333333333vw;
    margin: 0 1.3333333333vw 0.2666666667vw;
  }
}
.courses .course.ness .title {
  padding-bottom: 0.1041666667vw;
  white-space: wrap;
  line-height: 0.8;
}
@media only screen and (max-width: 767px) {
  .courses .course.ness .title {
    padding-bottom: 0;
  }
}
.courses .course .logo {
  max-width: 11.0416666667vw;
  max-height: 2.3958333333vw;
}
@media only screen and (max-width: 767px) {
  .courses .course .logo {
    max-width: 56.5333333333vw;
    max-height: 12.2666666667vw;
  }
}
.courses .course .logo img {
  width: auto;
  height: auto;
  max-width: inherit;
  max-height: inherit;
}
.courses .course .title {
  margin-top: 0.15625vw;
  margin-bottom: -0.0520833333vw;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  font-size: 2.0833333333vw;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
}
@media only screen and (max-width: 767px) {
  .courses .course .title {
    margin-top: 0.8vw;
    margin-bottom: 0;
    font-size: 10.9333333333vw;
  }
}
.courses .course .sub-title {
  text-align: center;
  font-size: 0.8854166667vw;
  line-height: 1;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
}
@media only screen and (max-width: 767px) {
  .courses .course .sub-title {
    margin-top: 2.6666666667vw;
    font-size: 4.5333333333vw;
  }
}
.courses .course .btn {
  display: flex;
  width: 8.8020833333vw;
  padding: 0.1041666667vw 0.2604166667vw;
  margin-top: 0.46875vw;
  margin-bottom: 1.4583333333vw;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  font-size: 0.8854166667vw;
  background-color: #143f90;
  border-radius: 1.8229166667vw;
}
@media only screen and (max-width: 767px) {
  .courses .course .btn {
    width: 44.8vw;
    height: 8vw;
    margin-top: 3.7333333333vw;
    margin-bottom: 5.3333333333vw;
    color: #f7f6f5;
    font-size: 4vw;
    border-radius: 8vw;
  }
}
.courses .price-tag {
  position: absolute;
  right: 0.5208333333vw;
  bottom: 0.625vw;
  display: inline-block;
  width: 100%;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  font-size: 0.6770833333vw;
  line-height: 0;
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .courses .price-tag {
    right: 3.7333333333vw;
    bottom: 1.0666666667vw;
    font-size: 4vw;
  }
}
.courses .price-tag::before {
  content: "*";
  position: absolute;
  top: 0;
  right: -0.3645833333vw;
  color: #000;
  font-size: 0.78125vw;
}
@media only screen and (max-width: 767px) {
  .courses .price-tag::before {
    top: 0;
    right: -1.8666666667vw;
    font-size: 4vw;
  }
}
.courses .logoAndTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}