@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.form-wrapper {
  position: relative;
  padding: 2.34375vw;
  background-color: #fff;
}
.form-wrapper .close {
  cursor: pointer;
  position: absolute;
  left: 0.78125vw;
  top: 0.6770833333vw;
  width: 0.78125vw;
}
@media only screen and (max-width: 767px) {
  .form-wrapper .close {
    width: 4vw;
    left: 4vw;
    top: 2.6666666667vw;
  }
}
.form-wrapper .close img {
  width: 100%;
}
.form-wrapper .form-title {
  font-size: 1.8229166667vw;
  line-height: 1.7708333333vw;
  letter-spacing: 1.26px;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .form-wrapper .form-title {
    padding-top: 6.6666666667vw;
    font-size: 5.46875vw;
    line-height: 5.3125vw;
    letter-spacing: 1.26px;
  }
}
.form-wrapper .form-sub-title {
  padding-top: 2.7083333333vw;
  padding-bottom: 1.3020833333vw;
  font-size: 1.40625vw;
  line-height: 1.7708333333vw;
  color: #000;
  font-family: "NarkisBlockCondensed-Thin", sans-serif;
}
@media only screen and (max-width: 767px) {
  .form-wrapper .form-sub-title {
    padding-top: 6.875vw;
    padding-bottom: 3.90625vw;
    font-size: 4.6875vw;
    line-height: 5.3125vw;
  }
}

.form-row {
  margin-top: 2.0833333333vw;
  margin-bottom: 1.6666666667vw;
}
@media only screen and (max-width: 767px) {
  .form-row {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row {
    margin-bottom: 2.34375vw;
  }
}
.form-row.second-form-row {
  margin-bottom: 35px;
}
@media only screen and (max-width: 767px) {
  .form-row.second-form-row {
    margin-bottom: 7.65625vw;
  }
}
.form-row.third-form-row {
  margin-bottom: 0;
}
.form-row.with-checkboxes {
  padding: 13px 0 6.5px;
}
@media only screen and (max-width: 767px) {
  .form-row.with-checkboxes {
    padding: 0 0 3.046875vw;
  }
}
.form-row.status-and-schedules .fields-wrapper .field-wrapper:first-child {
  margin-bottom: 14px;
}
@media only screen and (max-width: 767px) {
  .form-row.status-and-schedules .fields-wrapper .field-wrapper:first-child {
    margin-bottom: 3.4375vw;
  }
}
.form-row.executor-row .fields-wrapper .field-wrapper {
  margin-bottom: 21px;
}
@media only screen and (max-width: 767px) {
  .form-row.executor-row .fields-wrapper .field-wrapper {
    margin-bottom: 5vw;
  }
}
.form-row.executor-row .fields-wrapper .field-wrapper.text-field {
  margin-bottom: 34px;
}
@media only screen and (max-width: 767px) {
  .form-row.executor-row .fields-wrapper .field-wrapper.text-field {
    margin-bottom: 6.5625vw;
  }
}
.form-row.media-fields .row-title {
  padding-bottom: 0.15625vw;
}
@media only screen and (max-width: 767px) {
  .form-row.media-fields .row-title {
    padding-bottom: 0.78125vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row.media-fields .row-title {
    padding-bottom: 0.9375vw;
  }
}
.form-row.media-fields .media-fields-upload-info {
  padding-bottom: 18px;
  font-size: 19px;
  line-height: 22px;
  letter-spacing: 0.44px;
  font-family: "NarkisBlock-Regular", sans-serif;
  color: #000;
}
@media only screen and (max-width: 767px) {
  .form-row.media-fields .media-fields-upload-info {
    padding-bottom: 6.875vw;
    font-size: 3.90625vw;
    line-height: 4.53125vw;
    letter-spacing: 0.58px;
  }
}
.form-row.media-fields .media-fields-upload-info span {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.26px;
  font-family: "opensanshebreew_regular", sans-serif;
}
@media only screen and (max-width: 767px) {
  .form-row.media-fields .media-fields-upload-info span {
    font-size: 3.28125vw;
    line-height: 4.375vw;
    letter-spacing: 0.48px;
  }
}
.form-row.with-button {
  position: relative;
  display: flex;
  margin-top: 26.5px;
  margin-bottom: 27px;
  justify-content: center;
  align-items: center;
  border-bottom: none;
}
@media only screen and (max-width: 767px) {
  .form-row.with-button {
    margin-top: 0.78125vw;
    margin-bottom: 2.8125vw;
  }
}
.form-row.with-button .submit-btn {
  width: 15.9375vw;
  height: 2.8125vw;
  padding: 0;
  font-size: 1.6145833333vw;
  border: 0;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  background-color: #143f90;
  border-radius: 1.1458333333vw;
  color: #fff;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .form-row.with-button .submit-btn {
    width: 81.6vw;
    height: 14.4vw;
    padding: 0;
    font-size: 8.2666666667vw;
    border-radius: 16.8vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row.with-button .submit-btn {
    width: 31.25vw;
    height: 3.90625vw;
    font-size: 4.6875vw;
  }
}
.form-row.with-button .submit-btn.for-windows {
  padding-top: 0.5208333333vw;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row.with-button .submit-btn.for-windows {
    padding-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .form-row.with-button .submit-btn.for-windows {
    padding-top: 0;
  }
}
.form-row .fields-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form-row .media-fields-wrapper {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .form-row .media-fields-wrapper {
    justify-content: space-between;
  }
}
.form-row .media-fields-wrapper .field-wrapper {
  width: 217px;
  margin-left: 13px;
}
@media only screen and (max-width: 767px) {
  .form-row .media-fields-wrapper .field-wrapper {
    width: 30%;
    margin-left: 0;
  }
}
.form-row .field-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 1.7708333333vw;
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper {
    margin-bottom: 6.40625vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row .field-wrapper {
    margin-bottom: 4.6875vw;
  }
}
.form-row .field-wrapper.flex {
  display: flex;
  column-gap: 1.0416666667vw;
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper.flex {
    column-gap: 3.125vw;
  }
}
.form-row .field-wrapper .error {
  position: absolute;
  top: 100%;
  right: 0;
  color: red;
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper .error {
    font-size: 2.96875vw;
    line-height: 1.1;
  }
}
.form-row .field-wrapper.short-field {
  width: 24%;
  max-width: 24%;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper.short-field {
    width: 100%;
    max-width: 100%;
    margin-bottom: 3.4375vw;
  }
}
.form-row .field-wrapper.middle-field {
  width: 23.2291666667vw;
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row .field-wrapper.middle-field {
    width: 49%;
  }
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper.middle-field {
    width: 100%;
    margin-bottom: 5.15625vw;
  }
  .form-row .field-wrapper.middle-field:last-child {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper.middle-field-with-margin:last-child {
    margin-bottom: 5.46875vw;
  }
}
.form-row .field-wrapper .word-count {
  position: absolute;
  right: 0.5208333333vw;
  bottom: 0.2604166667vw;
  font-size: 0.8333333333vw;
  line-height: 1.0416666667vw;
  font-family: "opensanshebreew_regular", sans-serif;
}
@media only screen and (max-width: 767px) {
  .form-row .field-wrapper .word-count {
    padding-right: 1.5625vw;
    right: 1.5625vw;
    bottom: 0.78125vw;
    font-size: 2.5vw;
    line-height: 3.125vw;
  }
}
.form-row .row-title {
  padding-bottom: 0.8854166667vw;
  font-size: 1.09375vw;
  line-height: 1.3020833333vw;
  letter-spacing: 0.48px;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  color: #000;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row .row-title {
    padding-bottom: 1.5625vw;
    font-size: 1.875vw;
  }
}
@media only screen and (max-width: 767px) {
  .form-row .row-title {
    padding-bottom: 3.4375vw;
    font-size: 4.21875vw;
    line-height: 5vw;
    letter-spacing: 0.62px;
  }
}
.form-row .row-title span {
  font-family: "NarkisBlockCondensed-Thin", sans-serif;
}
@media only screen and (max-width: 767px) {
  .form-row .row-title span {
    font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
  }
}
@media only screen and (min-width: 1025px) {
  .form-row .row-title-with-other-padding {
    padding-bottom: 0.5208333333vw;
  }
}
@media only screen and (max-width: 767px) {
  .form-row .row-title-with-other-padding {
    padding-top: 3.90625vw;
  }
}
.form-row .radio-btns-wrapper {
  display: flex;
  padding-bottom: 2.0833333333vw;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .form-row .radio-btns-wrapper {
    padding-bottom: 2.578125vw;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-row .radio-btns-wrapper {
    padding-bottom: 2.34375vw;
  }
}
.form-row .radio-btns-wrapper .field-wrapper {
  width: auto;
  margin-bottom: 0;
  margin-left: 2.34375vw;
}
@media only screen and (max-width: 767px) {
  .form-row .radio-btns-wrapper .field-wrapper {
    margin-bottom: 5.9375vw;
    margin-left: 9.53125vw;
  }
}
.form-row .radio-btns-wrapper .field-wrapper:last-child {
  margin-left: 0;
}
.form-row .radio-btns-wrapper .radio-btns-wrapper-title {
  width: 100%;
  padding-bottom: 18px;
  font-size: 19px;
  line-height: 22px;
  letter-spacing: 0.44px;
  color: #000;
  font-family: "NarkisBlockCondensedMF-Medium", sans-serif;
}
@media only screen and (max-width: 767px) {
  .form-row .radio-btns-wrapper .radio-btns-wrapper-title {
    padding-bottom: 3.125vw;
    font-size: 3.90625vw;
    line-height: 4.53125vw;
    letter-spacing: 0.58px;
    font-family: "NarkisBlock-Regular", sans-serif;
  }
}
.form-row .radio-btns-wrapper.radio-btns-wrapper-with-title {
  padding-bottom: 23px;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .form-row .radio-btns-wrapper.radio-btns-wrapper-with-title {
    padding-bottom: 3.59375vw;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 767px) {
  .form-row .radio-btns-wrapper.radio-btns-wrapper-with-title .field-wrapper {
    margin-left: 6.40625vw;
  }
  .form-row .radio-btns-wrapper.radio-btns-wrapper-with-title .field-wrapper:last-child {
    margin-left: 0;
  }
}
.form-row .checkbox-wrapper {
  padding-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .form-row .checkbox-wrapper {
    padding-bottom: 4.0625vw;
  }
}

.file-placeholder {
  font-size: 1.5625vw;
  line-height: 1.8229166667vw;
  font-family: "NarkisBlockCondensed-Thin", sans-serif;
}
.file-placeholder span b {
  font-size: 1.09375vw;
  line-height: 1.5104166667vw;
  font-family: "opensanshebreew_regular", sans-serif;
  font-weight: normal;
}
.file-placeholder span i {
  font-size: 1.3020833333vw;
  line-height: 1.5104166667vw;
  font-family: "NarkisBlockCondensed-Thin", sans-serif;
  font-style: normal;
}

.captcha > div > div {
  margin: 0 auto;
}

.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.875vw;
  height: 1.875vw;
  z-index: 10;
}
@media only screen and (max-width: 767px) {
  .preloader {
    width: 5.625vw;
    height: 5.625vw;
  }
}
.preloader:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 0.1041666667vw solid white;
  border-bottom-color: transparent;
  animation-name: rotate;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform-style: preserve-3d;
}
@media only screen and (max-width: 767px) {
  .preloader:after {
    border: 0.3125vw solid white;
    border-bottom-color: transparent;
  }
}

.logo {
  width: 10.46875vw;
  margin: 0.5208333333vw auto 2.34375vw;
}
@media only screen and (max-width: 767px) {
  .logo {
    width: 48.75vw;
    margin: 5.46875vw auto 0.78125vw;
  }
}
.logo img {
  display: block;
  width: 100%;
}

@keyframes preloader-animation {
  0% {
    transform: translate3d(-50%, 0, 0) scale(2) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, 0, 0) scale(2) rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}