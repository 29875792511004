@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.checkbox {
  position: relative;
  display: flex;
}
.checkbox input {
  display: none;
}
.checkbox p {
  font-size: 0.9375vw;
  line-height: 1.09375vw;
  letter-spacing: 0.88px;
  color: #000;
  font-family: "NarkisBlockCondensed-Thin", sans-serif;
}
@media only screen and (max-width: 767px) {
  .checkbox p {
    font-size: 3.59375vw;
    line-height: 4.21875vw;
    letter-spacing: 0.69px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .checkbox p {
    font-size: 1.71875vw;
    line-height: 2.8125vw;
  }
}
.checkbox p a {
  text-decoration: underline;
}
.checkbox p span {
  font-family: "Open Sans", sans-serif;
}
.checkbox p a {
  text-decoration: underline;
}
.checkbox p a:hover {
  text-decoration: none;
}
.checkbox .control {
  position: relative;
  width: 19px;
  height: 19px;
  min-width: 19px;
  min-height: 19px;
  margin-bottom: 10px;
  margin-left: 8px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #143f90;
}
@media only screen and (max-width: 767px) {
  .checkbox .control {
    width: 4.8vw;
    height: 4.8vw;
    min-width: 4.8vw;
    min-height: 4.8vw;
    border-radius: 1.3333333333vw;
  }
}
.checkbox .control::before {
  content: "";
  position: absolute;
  top: 49%;
  left: 52%;
  display: none;
  width: 50%;
  height: 30%;
  border: 2px solid #143f90;
  border-right: 0;
  border-top: 0;
  transform: translate(-50%, -50%) rotate(-45deg);
}
@media only screen and (max-width: 767px) {
  .checkbox .control::before {
    border: 0.3125vw solid #143f90;
    border-right: 0;
    border-top: 0;
  }
}
.checkbox.active .control::before {
  display: block;
}

.rtl {
  direction: rtl;
}